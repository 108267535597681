


import React, { useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";


const DataContext = React.createContext();

export const useData = () => {
  return useContext(DataContext);
};



export const DataProvider = ({children}) => {

const data = useStaticQuery(graphql`
    {
      allWpPost {
        edges {
          node {
            title
            excerpt
            slug
            date
            id
            content
            blog {
              postAuthor {
                ... on WpBlogAuthor {
                  title
                  postTypeBlogAuthorsAuthorFields {
                    jobTitle
                  }
                  featuredImage {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                }
              }
            }
            featuredImage {
              node {
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                    )
                  }
                  url
                }

              }
            }
            categories {
              nodes {
                name
                slug
              }
            }
            tags {
              nodes {
                slug
                name
              }
            }
            author {
              node {
                name
              }
            }
          }
        }
      }

      allWpEvent {
        edges {
          node {
            slug
            date
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            events {
              eventDate
              eventLocation
              eventTime
              eventBooking {
                bookingUrl
              }
            }
            title
            content
            id
          }
        }
      }
    }
  `);
//const anotherValue = "example"
const sortedPosts = data.allWpPost.edges.sort((a,b)=>a.node.date<b.node.date?1:-1)

  return (
    <DataContext.Provider value={{data,sortedPosts}}>
        {children}
    </DataContext.Provider>
  );
};
